import React from 'react';
import { faCheck, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Button } from 'react-bootstrap';

export default class FinalCsv extends React.Component {
    render() {
        return (
            <div>
                <Alert variant={'success'}>
                    Esiti caricati correttamente <FontAwesomeIcon icon={faCheck} />
                </Alert>
                <div className='d-flex justify-content-center mb-3'>
                <Button onClick={this.props.reloadCsv} className='mt-3' variant='primary'>Carica un altro csv <FontAwesomeIcon icon={faSyncAlt} /></Button>
                </div>
            </div>)
    }
}