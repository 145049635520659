import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class AuthPage extends React.Component{
    render(){
        return(
        <div style={{textAlign: 'center'}}>
            <div style={{color: 'yellow'}}><FontAwesomeIcon size='4x' icon={faExclamationTriangle} /></div>
            <h3> Non siete autorizzati ad accedere all'applicativo</h3>
            <p>Si prega di effettuare il logout ed eventualmente cambiare utente</p>
        </div>)
    }
}

export default AuthPage;