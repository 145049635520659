import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

export default class LoadCsv extends React.Component{

    render(){
        return(<div>
            <div><input className='form-control-file' onChange={this.props.handleFileSelected} type='file' id='files' accept='.csv' required></input></div>
            {this.props.csvData ? 
            <Button onClick={this.props.generateJsonFromCsv} className='mt-3' variant='success'>Elabora <FontAwesomeIcon icon={faFileCsv} /></Button> : null}
        </div>)
    }
}