import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class ErrorModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (<Modal show={this.props.showModalConflitto} onHide={this.props.closeModalConflitto}>
            <Modal.Header closeButton>
                <Modal.Title>
                   ERRORE
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.props.errors[0].status === 403 ? 'Sessione scaduta, si prega di ri-effettuare il login' : this.props.errors[0].error}
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                {this.props.errors[0].status === 403 ? 
                <Button onClick={this.props.logout} variant="secondary">
                    Rieffettua il login
                </Button> : null}
            </Modal.Footer>
        </Modal>)
    }
}

export default ErrorModal;