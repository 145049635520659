import React from 'react';
import { Modal, Button, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Fragment } from 'react';
import Loader from '../components/Loader';



class ModalAdminPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            dataModal: props.dataModal
        }
    }

    componentDidMount() {
        // this.setState({dataModal: this.props.dataModal})
    }

    saveUser = () => {
        let params = {
            user: {
                USER: this.state.dataModal.USER,
                ROLE: this.state.dataModal.ROLE
            }
        }
        axios.post(`${process.env.REACT_APP_API_URL}/addUser`, params, this.props.authParam).then((resp) => {
            this.props.closeModalAdminPage(true);
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    updateUser = () => {
        let params = {
            user: {
                USER: this.state.dataModal.USER,
                ROLE: this.state.dataModal.ROLE
            }
        }
        axios.post(`${process.env.REACT_APP_API_URL}/updateUser`, params, this.props.authParam).then((resp) => {
            this.props.closeModalAdminPage(true);
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    deleteUser = () => {
        let params = {
            user: {
                USER: this.state.dataModal.USER,
                ROLE: this.state.dataModal.ROLE
            }
        }
        axios.post(`${process.env.REACT_APP_API_URL}/deleteUser`, params, this.props.authParam).then((resp) => {
            this.props.closeModalAdminPage(true);
        }).catch((err) => {
            this.props.setErrors(err);
        })

    }

    onChangeValue = (e, name) => {
        let dataModal = this.state.dataModal;
        let specials=/[*|\":<>[\]{}`\\()';&$]/;
        if(!specials.test(e.target.value)){
            dataModal[name] = e.target.value;
            this.setState({ dataModal: dataModal })
        }

    }

    closeModalAdminPage = () => {
        this.props.closeModalAdminPage();
    }



    render() {
        return (
            <Modal show={this.props.showModalAdminPage} onHide={() => this.closeModalAdminPage(false)} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{ color: 'white' }}>
                        {this.props.adminPageAction === 'I' ? 'INSERISCI UTENTE' : this.props.adminPageAction === 'M' ? 'MODIFICA UTENTE' : "SEI SICURO DI VOLER CANCELLARE L'UTENTE?"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading ?
                        <Loader /> :
                            this.state.dataModal ? 
                            <Fragment>
                            <Row>
                                <Col xl={8}>
                                    <label className='customLabel' htmlFor="basic-url">Utente:</label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            aria-label="Default"
                                            onChange={(e) => this.onChangeValue(e, 'USER')}
                                            value={this.state.dataModal.USER}
                                            placeholder={'Utente'}
                                            aria-describedby="inputGroup-sizing-default"
                                            disabled = {this.props.adminPageAction === 'M' || this.props.adminPageAction === 'D' ? true : false}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xl={4}>
                                    <label className='customLabel' htmlFor="basic-url">Ruolo:</label>
                                    <select className='form-control' value={this.state.dataModal.ROLE} 
                                    disabled = {this.props.adminPageAction === 'D' ? true: false}
                                    onChange={(e) => this.onChangeValue(e, 'ROLE')}
                                    >
                                        <option value=''></option>
                                        <option value='ADMIN'>ADMIN</option>
                                        <option value='W'>W</option>
                                        <option value='R/O'>R/O</option>
                                    </select>
                                </Col>
                            </Row>
                        </Fragment> : null}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={() => this.closeModalAdminPage(false)}>
                        Annulla
                    </Button>
                    {this.props.adminPageAction !== 'D' ? 
                    <Button variant="primary" onClick={this.props.adminPageAction === 'I' ? this.saveUser :  this.updateUser}>
                        Salva
                    </Button> : 
                    <Button variant="danger"  onClick={this.deleteUser}>Elimina</Button>}
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalAdminPage;