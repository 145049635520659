import React from 'react';
import { faDatabase, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListGroup, Button } from 'react-bootstrap';

export default class DetailsCsv extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nEsiti: props.csvJson.length - 1,
            data: props.csvJson
        }

    }

    setRiepilogo = (esito) =>  {
        let riepilogo = `Matricola: ${esito[0]} Tipologia esito: ${esito[1]} Livello: ${esito[2]} Data svolgimento: ${esito[3]}`;
        return <span>{riepilogo}</span>
    }


    render() {
        return (
            <div>
                <h3>Risultati:</h3>
                <div>Esiti elaborati: {this.state.nEsiti}</div>
                <div>Errori riscontrati: 0</div>
                <h3>Riepilogo:</h3>
                <div className='listContainer'>
                    <ListGroup variant="flush">
                        {this.state.data  ? this.state.data.map((esito, idx) => 
                        idx !== 0 ? <ListGroup.Item key={idx}>{this.setRiepilogo(esito)}</ListGroup.Item>  : null)  
                        : null}
                    </ListGroup>
                </div>
                <div className='d-flex justify-content-center mb-3'>
                    <Button onClick={this.props.saveData} className='mt-3 mr-2' variant='success'>Salva dati DB <FontAwesomeIcon icon={faDatabase} /></Button>
                    <Button onClick={this.props.reloadCsv} className='mt-3' variant='primary'>Ricarica csv <FontAwesomeIcon icon={faSyncAlt} /></Button>
                </div>
                
            </div>)
    }
}