import React from 'react';
import { Button, Alert, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFileCsv, faCheck, faHome } from '@fortawesome/free-solid-svg-icons'
import papa from 'papaparse';
import axios from 'axios';

class ImportaMatricole extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            csvStatus: 'load',
            csvData: null,
            csvJson: null,
            alertVisible: false,
        }


    }

    handleFileSelected = (e) => {
        this.setState({ csvData: e.target.files[0] })
    }

    generateJsonFromCsv = () => {
        let self = this;
        if (this.state.csvData) {
            papa.parse(this.state.csvData, {
                complete: function (results) {
                    self.callApi(results.data);
                    self.setState({ csvStatus: 'details', csvJson: results.data })
                },
                skipEmptyLines: true
            });
        }

    }

    callApi = (data) => {
        let self = this;
        data.shift();
        axios.post(`${process.env.REACT_APP_API_URL}/insertMatricole`, data,  this.props.authParam).then((resp) => {
            document.getElementById('files').value = '';
            this.setState({ csvData: null, alertVisible: true })

            setTimeout(function(){
                self.setState({alertVisible: false})
            }, 1000)
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    goBack = () => {
        this.setParentFilters().then(() => {
            this.props.history.goBack();
        })
    }

    render() {
        return (
            <div>
                {/* <div><Button variant="primary" onClick={this.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Torna alla Home </Button></div> */}
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                    <Breadcrumb.Item active={true}>
                        Importa matricole
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className='mt-3'>
                    <h3>IMPORTA MATRICOLE</h3>
                    <p>In questa sezione è possibile caricare i file matricola. Il delimitatore sarà il ";"</p>
                </div>
                <div>
                    <div>
                        <input className='form-control-file' onChange={this.handleFileSelected} type='file' id='files' accept='.csv' required></input></div>
                    {this.state.csvData ?
                        <Button onClick={this.generateJsonFromCsv} className='mt-3' variant='success'>Elabora <FontAwesomeIcon icon={faFileCsv} /></Button> : null}
                    {this.state.alertVisible ? <Alert variant={'success'} className='mt-2'>
                        Esiti caricati correttamente <FontAwesomeIcon icon={faCheck} />
                    </Alert> : null}
                </div>
            </div>)
    }
}

export default ImportaMatricole;