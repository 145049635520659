import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { Fragment } from 'react';
import Loader from '../components/Loader';


class ModalMancante extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            statiStudente: null,
            selectedStatoStudente: 'RINUNCIA',
            selectedStudente: [],
        }
    }

    componentDidMount() {
        let param = {
            NOME: this.props.studente.nome,
            COGNOME: this.props.studente.cognome,
            COD_FISCALE: this.props.studente.cf,
            EMAIL: this.props.studente.email,
        }

        this.setState({ isLoading: true })
        axios.get(`${process.env.REACT_APP_API_URL}/getStatiMancanti`, this.props.authParam).then((resp) => {
            let stati = resp.data.filter(stato => stato.DESC_STATO !== 'ATTIVO');
            this.setState({ statiStudente: stati, isLoading: false})
        }).catch((err) => {
            this.props.setErrors(err);
        })
         
    }

    onChangeValueStatoStudente = (e) =>  {
        this.setState({selectedStatoStudente: e.target.value})
    }

    salvaMancante = () => {
        let check = this.checkField();
        if(check){
            let param = {
                stato: this.state.selectedStatoStudente,
                studente: this.state.selectedStudente,
                id_studente: this.props.id_studente
            }
            axios.post(`${process.env.REACT_APP_API_URL}/saveMancante`, param, this.props.authParam).then((resp) => {
                this.props.hideAlertMancante(true);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        } else {
            alert('Seleziona tutti i campi obbligatori')
        }
    }

    
    checkField = () => {
        if(this.state.selectedStatoStudente === ''){
            return false;
        }
        return true;
    }
    


    render() {
        return (
            <Modal show={this.props.showModalMancante} onHide={this.props.closeModalMancante} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{color: 'white'}}>
                        {this.props.studente.cognome + " " + this.props.studente.nome}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading ?
                        <Loader /> :
                        <Fragment>
                            <label className='customLabel' htmlFor="basic-url">Seleziona lo stato che assumerà la carriera scartata</label>
                            <div style={{ width: '40%' }}>
                                <select className='form-control' onChange={this.onChangeValueStatoStudente}
                                    value={this.state.selectedStatoStudente}>
                                    {this.state.statiStudente ? this.state.statiStudente.map(statoSt => <option value={statoSt.DESC_STATO} key={statoSt.ID_STATO_STUDENTE}>{statoSt.DESC_STATO}</option>) : <option></option>}
                                </select>
                            </div>
                        </Fragment>}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={this.props.closeModalMancante}>
                        Annulla
                    </Button>
                    <Button onClick={this.salvaMancante} variant="primary">
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalMancante;