import React from 'react';
import { Spinner } from 'react-bootstrap';

export default class Loader extends React.Component {
    render() {
        return (
            <div className='loader' >
                <Spinner animation="border" style={this.props.customStyle ? this.props.customStyle : null} className='blueUnimiColor' role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>)
    }
}