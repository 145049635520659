import React, { Fragment } from 'react';
import papa from 'papaparse';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faSyncAlt, faSave } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';


class SceltaSLAM extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            csvData: null,
            elaborato: false
        }

    }

    handleFileSelected = (e) => {
        this.setState({ csvData: e.target.files[0] })
    }

    generateJsonFromCsv = () => {
        let self = this;
        if (this.state.csvData) {
            papa.parse(this.state.csvData, {
                encoding: "UTF-8",
                complete: function (results) {
                    self.checkFields(results.data);
                    self.updateCsvData(results.data);
                    self.setState({ elaborato: true })
                },
                skipEmptyLines: true
            });
        }

    }

    checkFields = (results) => {
        //call api to get id_studente
        if (results) {
            let mappedRes = results.map((item, idx) => {
                return item[6]
            })

            mappedRes.shift();

            axios.post(`${process.env.REACT_APP_API_URL}/checkDataPercorsoSLAM`, mappedRes, this.props.authParam).then((resp) => {
                if (resp.data && resp.data.length === 0) {
                    //casistica elemento da aggiornare non trovato
                    alert('Uno o più elementi non sono presenti a sistema, ricontrolla gli elementi inseriti')
                    this.reloadCsv();

                } else {
                    //casistica aggiorna studenti
                    this.setState({ IDs: resp.data})

                }
            }).catch((err) => {
                this.props.setErrors(err);
            })
        }
    }


    updateCsvData = (csvJson) => {
        this.setState({ csvJson: csvJson })
    }

    setRiepilogo = (esito) => {
        return <span><b className='customLabel'>Matricola:</b> {esito[0]} <b className='customLabel'>Cognome:</b> {esito[4]} <b className='customLabel'>Nome:</b> {esito[5]} </span>
    }

    reloadCsv = () => {
        this.updateCsvData(null);
        this.setState({ csvData: null, elaborato: false })
    }

    saveData = () => {
        let IDs = this.state.IDs;
        axios.post(`${process.env.REACT_APP_API_URL}/updatePercorsoSLAM`, IDs, this.props.authParam).then((resp) => {
        }).catch((err) => {
            this.props.setErrors(err);
        });
    }

    render() {
        return (
            <Fragment>
                <div className='mt-3'>
                    <h3>SCELTA PERCORSO SLAM</h3>
                    <p>In questa sezione è possibile selezionare gli studenti che hanno deciso di non effettuare il percorso SLAM. Il file da caricare avrà come delimitatore ";"</p>
                </div>
                {!this.state.elaborato ? <input className='form-control-file' onChange={this.handleFileSelected} type='file' id='files' accept='.csv' required></input> : null}
                {this.state.csvData && !this.state.elaborato ? <Button onClick={this.generateJsonFromCsv} className='mt-3' variant='success'>Elabora <FontAwesomeIcon icon={faFileCsv} /></Button> : null}
                {this.state.elaborato ?
                    <div>
                        {this.state.csvJson ? <div><b>Caricati:</b> {this.state.csvJson.length}</div> : null}
                        <div className='listContainer'>
                            <ListGroup variant="flush">
                                {this.state.csvJson ? this.state.csvJson.map((esito, idx) =>
                                    idx !== 0 ? <ListGroup.Item key={idx}>{this.setRiepilogo(esito)}</ListGroup.Item> : null)
                                    : null}
                            </ListGroup>
                        </div>
                        <div className='d-flex justify-content-center mb-3'>
                            <Button onClick={this.saveData} className='mt-3 mr-2' variant='success'>Aggiorna percorso SLAM <FontAwesomeIcon icon={faSave} /></Button>
                            <Button onClick={this.reloadCsv} className='mt-3' variant='primary'>Carica un altro csv <FontAwesomeIcon icon={faSyncAlt} /></Button>
                        </div>
                    </div>
                    : null}
            </Fragment>
        )
    }
}

export default SceltaSLAM;