import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { Fragment } from 'react';
import Loader from '../components/Loader';


class ModalConflitto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conflitti: null,
            isLoading: false,
            statiStudente: null,
            selectedStatoStudente: 'RINUNCIA',
            selectedStudente: [],
            isActiveChoice: true,
            isDoppiaCarriera: false
        }
    }

    componentDidMount() {
        let param = {
            NOME: this.props.studente.anagrafica.nome,
            COGNOME: this.props.studente.anagrafica.cognome,
            COD_FISCALE: this.props.studente.anagrafica.cf,
            EMAIL: this.props.studente.anagrafica.email,
        }

        this.setState({ isLoading: true })
        axios.post(`${process.env.REACT_APP_API_URL}/getConflitti`, param, this.props.authParam).then((resp) => {
            this.setState({ conflitti: resp.data, isLoading: false })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getStatiConflitto`, this.props.authParam).then((resp) => {
            let stati = resp.data.filter(stato => stato.DESC_STATO !== 'ATTIVO');
            this.setState({ statiStudente: stati })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    onChangeValueStatoStudente = (e) => {
        this.setState({ selectedStatoStudente: e.target.value })
    }

    salvaConflitto = () => {
        let check = this.checkField();
        if (check) {
            let param = {
                stato: this.state.selectedStatoStudente,
                studente: this.state.selectedStudente,
                isActiveChoice: this.state.isActiveChoice,
                conflitti: this.state.conflitti,
                livello: this.props.studente.anagrafica.livello,
                id_studente: this.props.id_studente,
                carriera: this.props.studente,
                isDoppiaCarriera: this.state.isDoppiaCarriera
            }
            axios.post(`${process.env.REACT_APP_API_URL}/saveConflitto`, param, this.props.authParam).then((resp) => {
                // this.setState({ conflitti: resp.data })
                let idStudente = resp.data.ID ? resp.data.ID : null;
                let codCdl = resp.data.COD_CDL ? resp.data.COD_CDL : null;
                this.props.hideAlertConflitto(true, idStudente, codCdl);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        } else {
            alert('Seleziona tutti i campi obbligatori')
        }
    }

    setCorrectStudent = (e, studente) => {
        studente.CONVOCAZIONE_PT = this.props.studente.anagrafica.convocazione;
        this.setState({ selectedStudente: studente, isActiveChoice: e.currentTarget.value === 'attiva' })
    }

    checkField = () => {
        if ((this.state.selectedStatoStudente === '' || this.state.selectedStudente.length === 0) && !this.state.isDoppiaCarriera) {
            return false;
        }
        return true;
    }

    setDoppiaCarriera = () => {
        this.setState({isDoppiaCarriera: !this.state.isDoppiaCarriera, selectedStudente: []})
    }

    render() {
        return (
            <Modal show={this.props.showModalConflitto} onHide={this.props.closeModalConflitto} size="lg">
                <Modal.Header closeButton className='blueUnimiBG'>
                    <Modal.Title style={{ color: 'white' }}>
                        {this.props.studente.anagrafica.cognome + " " + this.props.studente.anagrafica.nome}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading ?
                        <Loader /> :
                        <Fragment>
                            <h5>Seleziona la riga corretta per risolvere il conflitto</h5>
                            <h6>Carriera ATTIVA:</h6>
                            <Table bordered>
                                <tbody>
                                    <tr>
                                        <td className={'blueUnimiBG'} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                            <label class="containerInputbox">
                                                <input disabled = {this.state.isDoppiaCarriera} onChange={(e) => this.setCorrectStudent(e, this.props.studente.anagrafica)} type="radio" id="attiva" value={'attiva'} name="choice" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </td>
                                        <td style={{ maxWidth: '20rem' }}>
                                            <div><span className='customLabel'>MATRICOLA:</span> {this.props.studente.anagrafica.matricola}</div>
                                            <div><span className='customLabel'>CODICE CDL:</span> {this.props.studente.anagrafica.cod_cdl}</div>
                                            <div><span className='customLabel'>CORSO DI LAUREA:</span> {this.props.studente.anagrafica.cdl}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <hr></hr>
                            <h6>CONFLITTI:</h6>
                            <Table bordered>
                                <tbody>
                                    {this.state.conflitti ? this.state.conflitti.map((conflitto, idx) =>
                                        <tr key={idx}>
                                            <td className={'blueUnimiBG'} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <label class="containerInputbox">
                                                    <input disabled = {this.state.isDoppiaCarriera} onChange={(e) => this.setCorrectStudent(e, conflitto)} type="radio" id={"inattiva_" + idx} value={'inattiva'} name={"choice"} />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </td>
                                            <td style={{ maxWidth: '20rem' }}>
                                                <div><span className='customLabel'>MATRICOLA:</span> {conflitto.MATRICOLA}</div>
                                                <div><span className='customLabel'>CODICE CDL:</span> {conflitto.COD_CDL}</div>
                                                <div><span className='customLabel'>CORSO DI LAUREA:</span> {conflitto.CDL}</div>
                                            </td>
                                        </tr>) : null}
                                </tbody>
                            </Table>

                            <span className='customLabel mr-2'>Si tratta di una doppia iscrizione?</span>
                            <span id="doppiaCarrieraConflittoBox">
                                <label class="switch">
                                    <input type="checkbox" onChange={this.setDoppiaCarriera} checked={this.state.isDoppiaCarriera} />
                                    <span class="slider round"></span>
                                </label>
                            </span>
                            {!this.state.isDoppiaCarriera ? 
                                <div id="standardConflittoBox">
                                    <label className='customLabel' htmlFor="basic-url">Scrivi di seguito lo stato che assumerà la carriera scartata</label>
                                    <div style={{ width: '40%' }}>
                                    <select className='form-control' onChange={this.onChangeValueStatoStudente}
                                        value={this.state.selectedStatoStudente}>
                                        {this.state.statiStudente ? this.state.statiStudente.map(statoSt => <option value={statoSt.DESC_STATO} key={statoSt.ID_STATO_STUDENTE}>{statoSt.DESC_STATO}</option>) : <option></option>}
                                    </select>
                                </div>
                            </div> : null}
                        </Fragment>}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button variant="secondary" onClick={this.props.closeModalConflitto}>
                        Annulla
                    </Button>
                    <Button onClick={this.salvaConflitto} variant="primary">
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>)
    }
}

export default ModalConflitto;