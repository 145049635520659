import React, { Fragment } from 'react';
import axios from 'axios';
import { Button, Row, Col, Card, CardDeck, Alert, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faIdCard, faClipboardList, faPencilAlt, faAward, faUserGraduate, faChartLine, faCheckDouble, faExclamationTriangle, faPlusSquare, faCheck, faTimes, faHome, faHistory, faLink, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import * as config from '../config/config';
import DataTable from 'react-data-table-component';
import ModalConflitto from '../sections/ModalConflitto';
import ModalMancante from '../sections/ModalMancante';
// import ModalAddInfo from '../sections/ModalAddInfo';
import ModalUserData from '../sections/ModalUserData';
import Loader from '../components/Loader';
import { withRouter } from "react-router";
import Breadcrumb from 'react-bootstrap/Breadcrumb'



class DettaglioStudente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            studente: null,
            showModalConflitto: false,
            showModalMancante: false,
            showModalAdd: false,
            showModalUserData: false,
            typeModal: null,
            reloadPage: false,
            idDoppiaImmatricolazione: null,
            dataModal: {
                id_valutazione: '',
                anagrafica: {
                    fields: [
                        { name: 'matricola', label: 'Matricola', value: '', type: 'input', size: 6, maxLength: 6 },
                        { name: 'cod_cdl', label: 'Cod. Cdl', value: '', type: 'optionCdl', size: 6 },
                        // { name: 'cdl', label: 'Corso di Laurea', value: '', type: 'input', size: 12 },
                        { name: 'cognome', label: 'Cognome', value: '', type: 'input', size: 6 },
                        { name: 'nome', label: 'Nome', value: '', type: 'input', size: 6 },
                        // { name: 'cf', label: 'Codice Fiscale', value: '', type: 'input', size: 6 },
                        { name: 'email', label: 'Email', value: '', type: 'input', size: 6 },
                        { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6 },
                        // { name: 'data_iscrizione', label: 'Data Immatricolazione', value: '', type: 'calendar', size: 6 },
                        { name: 'convocazione', label: 'Convocazione PT', value: '', type: 'optionMailPT', size: 6 },
                        { name: 'stato_studente', label: 'Stato studente', value: '', type: 'optionStatoStudente', size: 6 },

                    ]
                },
                placement_test: {
                    tipologiaEsito: 0,
                    fields: [
                        { name: 'data', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                        { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },

                    ]
                },
                test_finale: {
                    tipologiaEsito: 1,
                    fields: [
                        { name: 'data', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                        { name: 'descrizione', label: 'Descrizione', value: '', type: 'input', size: 6, isObbligatorio: true },
                        { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },

                    ]
                },
                certificazione: {
                    tipologiaEsito: 2,
                    fields: [
                        { name: 'data', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                        { name: 'descrizione', label: 'Descrizione', value: '', type: 'input', size: 6, isObbligatorio: false },
                        { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },

                    ]
                },
                tentativi_successivi: {
                    tipologiaEsito: 3,
                    fields: [
                        { name: 'data', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                        { name: 'descrizione', label: 'Descrizione', value: '', type: 'input', size: 6, isObbligatorio: true },
                        { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },

                    ]
                },
                test_di_ingresso: {
                    tipologiaEsito: 4,
                    fields: [
                        { name: 'data', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                        { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },
                    ]
                },
                livello_triennale: {
                    tipologiaEsito: 5,
                    fields: [
                        { name: 'data', label: 'Data', value: '', type: 'calendar', size: 6, isObbligatorio: true },
                        { name: 'livello', label: 'Livello', value: '', type: 'optionLivelli', size: 6, isObbligatorio: true },

                    ]
                },
            }
        }

        this.columns = [
            {
                name: 'Title',
                selector: row => row.data,
                sortable: true,
                cell: row => <div title={row.data}>{'Test finale ' + moment(row.data).format('DD/MM/YYYY')}</div>,
            },
            {
                name: 'Descrizione',
                selector: row => row.descrizione,
                sortable: true,
                cell: row => <div title={row.descrizione}>{row.descrizione}</div>,
            },
            {
                name: 'Livello',
                selector: row => row.livello,
                sortable: true,
                cell: row => <div title={row.livello}>{config.decodeLivello[row.livello]}</div>,
            },
            {
                selector: 'livello',
                cell: (row, index) => <div><FontAwesomeIcon icon={faPencilAlt} onClick={() => this.openModalMod('test_finale', this.state.studente.test_finale[index], 'M')} className={'modifyCustomButton'} /></div>,
            }
        ];
    }



    componentDidMount() {
        this.callStudente(null, null);

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({ isMobile: true })
        }

        axios.get(`${process.env.REACT_APP_API_URL}/getLivelli`, this.props.authParam).then((resp) => {
            this.setState({ livelli: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })
    }

    callStudente = (idStudente, codCdl) => {
        let ID = idStudente ? idStudente : this.props.location.state.studente.ID;
        let COD_CDL = codCdl ? codCdl : this.props.location.state.studente.COD_CDL;
        let isSceltaLibera = this.props.location.state.studente.CORSO_DI_LAUREA === null ? 'Y': 'N';
        axios.get(`${process.env.REACT_APP_API_URL}/getStudenteById/${ID}/${isSceltaLibera}/${COD_CDL}`, this.props.authParam).then((resp) => {
            resp.data.anagrafica.id = ID;
            this.setState({ studente: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getNumeroCarriere/${this.props.location.state.studente.EMAIL}`, this.props.authParam).then((resp) => {
            this.setState({ numeroCarriere: resp.data })
        }).catch((err) => {
            this.props.setErrors(err);
        })

        axios.get(`${process.env.REACT_APP_API_URL}/getDoppiaImmatricolazione/${this.props.location.state.studente.EMAIL}/${ID}`, this.props.authParam).then((resp) => {
            this.setState({ idDoppiaImmatricolazione: resp.data.ID, cdlDoppiaImmatricolazione: resp.data.COD_CDL })
        }).catch((err) => {
            this.props.setErrors(err);
        })

    }

    closeModalConflitto = () => {
        this.setState({ showModalConflitto: false })
    }

    openModalMancante = () => {
        this.setState({ showModalMancante: true })
    }

    closeModalMancante = () => {
        this.setState({ showModalMancante: false })
    }

    openModalConflitto = () => {
        this.setState({ showModalConflitto: true })
    }

    hideAlertConflitto = (isSalvataggio, idStudente, codCdl) => {
        if (isSalvataggio) {
            this.reloadPage(idStudente, codCdl);
        }
        this.setState({ showModalConflitto: false })
    }

    hideAlertMancante = (isSalvataggio) => {
        if (isSalvataggio) {
            this.reloadPage(this.props.location.state.studente.ID, this.props.location.state.studente.COD_CDL);
        }
        this.setState({ showModalMancante: false })
    }

    setParentFilters = () => {
        let self = this;
        return new Promise((resolve, reject) => {
            self.props.setFilters(this.props.location.state.filters, this.props.location.state.isActiveSearch);
            resolve();
        })
    }

    goBack = () => {
        this.setParentFilters().then(() => {
            this.props.history.goBack();
        })
    }

    closeModalAdd = () => {
        this.setState({ showModalAdd: false })
    }

    closeModalMod = (isSalvataggio, closeModalMod, idStudente) => {
        if (isSalvataggio) {
            this.reloadPage(idStudente, closeModalMod);
        }
        this.setState({ showModalUserData: false })
    }


    openModalMod = (type, params, insertOMod) => {
        let dataModal = this.state.dataModal;
        if (insertOMod === 'M') {
            dataModal.id_valutazione = params.id_valutazione;
            dataModal[type].fields.map(function (p) {
                if (params[p.name] && p.type === 'calendar') p.value = new Date(params[p.name])
                else if (p.name === 'stato_studente') p.value = config.mapIdStudente[params[p.name]];
                else if (params[p.name] || params[p.name] === 0) p.value = params[p.name]
                return p;
            })
        } else if (insertOMod === 'I' && type === 'tentativi_successivi') {
            dataModal[type].fields.map(function (p) {
                if (p.type === 'calendar') p.value = new Date()
                p.value = ''
                return p;
            })
        }

        this.setState({ showModalUserData: true, typeModal: type, insertOMod: insertOMod })
    }

    reloadPage = (idStudente, codCdl) => {
        this.setState({ reloadPage: true });
        setTimeout(() => {
            this.callStudente(idStudente, codCdl);
            this.setState({ reloadPage: false })
        }, 300)
    }

    goToStudentHistory = () => {
        this.props.history.push({ pathname: '/history', state: { email: this.state.studente.anagrafica.email, filters: this.props.location.state.filters, isActiveSearch: this.props.location.state.isActiveSearch } })
    }

    eliminaEsito = (id_valutazione, livelloAttuale) => {
        if (window.confirm("Sei sicuro di voler eliminare l'esito")) {
            let params = {
                id_valutazione: id_valutazione,
                livelloAttuale: livelloAttuale,
                nEsiti: this.state.studente.nEsiti,
                id_studente: this.props.location.state.studente.ID,
                studente: this.state.studente
            }

            axios.post(`${process.env.REACT_APP_API_URL}/deleteEsito`, params, this.props.authParam).then((resp) => {
                this.reloadPage(this.props.location.state.studente.ID, this.props.location.state.studente.COD_CDL);
            }).catch((err) => {
                this.props.setErrors(err);
            })
        }
    }

    isStorico = () => {
        if (this.state.numeroCarriere) {
            if (this.state.numeroCarriere > 1 && this.state.studente.anagrafica.doppia_iscrizione !== 'Y') return true;
            else if (this.state.numeroCarriere > 2 && this.state.studente.anagrafica.doppia_iscrizione === 'Y') return true;
        } return false;
    }

    render() {

        const ExpandedComponent = ({ data, index }) => (
            <div className={'pl-4'}>
                {data.tentativi_successivi.length > 0 ?
                    (data.tentativi_successivi.map((ts, idx) =>
                        <Row key={idx} className='mt-2' style={{ width: '100%', fontSize: '13px' }}>
                            <Col xl={3}><span className='customLabel'> {"Tentativo successivo " + (idx + 1)}</span></Col>
                            <Col xl={2}><span className='customLabel'>Livello:</span> {config.decodeLivello[ts.livello]}</Col>
                            <Col xl={2}><span className='customLabel'>Data:</span> {moment(ts.data).format('DD/MM/YYYY')}</Col>
                            <Col xl={4}><span className='customLabel'>Descrizione:</span> {ts.descrizione} </Col>
                            <Col xl={1}><span className='customLabel'></span> <FontAwesomeIcon onClick={() => this.openModalMod('tentativi_successivi', data.tentativi_successivi[idx], 'M')} className={'modifyCustomButton'} icon={this.state.studente.test_finale ? faPencilAlt : faPlusSquare} /></Col>
                        </Row>)) : null
                }
                {parseInt(data.livello) === 8 && data.tentativi_successivi && data.tentativi_successivi.filter(tts => parseInt(tts.livello) !== 8).length === 0 && data.tentativi_successivi.filter(tts => parseInt(tts.livello) === 8).length < 5 ?
                    <Col>
                        <div className='mt-4 d-flex justify-content-center'>
                            <Button onClick={() => this.openModalMod('tentativi_successivi', data.tentativi_successivi[index], 'I')}>Aggiungi Tentativo successivo</Button>
                        </div>
                    </Col> : null}
            </div>)

        return (
            <div>
                {this.state.studente && !this.state.reloadPage ? <Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={this.goBack}><FontAwesomeIcon icon={faHome} /> Home </Breadcrumb.Item>
                        <Breadcrumb.Item active={true}>
                            Dettaglio studente
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    {/* <Button variant="primary mb-3" onClick={this.goBack}><FontAwesomeIcon icon={faArrowLeft} /> Torna alla Home </Button> */}
                    {this.state.showModalConflitto ? <ModalConflitto id_studente={this.state.studente.anagrafica.id} authParam={this.props.authParam} hideAlertConflitto={this.hideAlertConflitto} studente={this.state.studente} showModalConflitto={this.state.showModalConflitto} closeModalConflitto={this.closeModalConflitto} /> : null}
                    {this.state.showModalMancante ? <ModalMancante id_studente={this.state.studente.anagrafica.id} authParam={this.props.authParam} hideAlertMancante={this.hideAlertMancante} studente={this.state.studente.anagrafica} showModalMancante={this.state.showModalMancante} closeModalMancante={this.closeModalMancante} /> : null}
                    {this.state.showModalUserData ?
                        <ModalUserData
                            typeModal={this.state.typeModal}
                            livelli={this.state.livelli}
                            statiStudente={this.state.statiStudente}
                            idStudente={this.state.studente.anagrafica.id}
                            setErrors={this.props.setErrors}
                            authParam={this.props.authParam}
                            insertOMod={this.state.insertOMod}
                            dataModal={this.state.dataModal[this.state.typeModal]}
                            tipologiaEsito={this.state.dataModal[this.state.typeModal].tipologiaEsito}
                            showModalUserData={this.state.showModalUserData}
                            livelloPrecedente={this.state.studente.anagrafica.livello}
                            id_valutazione={this.state.dataModal.id_valutazione}
                            closeModalMod={this.closeModalMod}
                            idDoppiaImmatricolazione = {this.state.idDoppiaImmatricolazione} />
                        : null}
                    {this.state.studente.isConflitto ?
                        <Row className='alertConflitti'>
                            <Col>
                                <Alert className='mt-3' variant={'warning'}>
                                    <div style={{ textAlign: 'center' }}><FontAwesomeIcon size='2x' icon={faExclamationTriangle} /></div>
                                    <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Sono presenti dei conflitti:</div>
                                    <div>In fase di import delle matricole sono cambiati il CDL e/o la matricola</div>
                                    <div style={{ textAlign: 'center' }}><Button onClick={this.openModalConflitto} className='mt-2' variant="danger">Gestisci conflitti</Button></div>
                                </Alert>
                            </Col>
                        </Row> : null}
                    {this.state.studente.isMancante ?
                        <Row className='alertConflitti'>
                            <Col>
                                <Alert className='mt-3' variant={'primary'}>
                                    <div style={{ textAlign: 'center' }}><FontAwesomeIcon size='2x' icon={faExclamationTriangle} /></div>
                                    <div style={{ fontWeight: 'bold', textAlign: 'center' }}>La matricola selezionata è risultata mancante nell'ultimo import:</div>
                                    {/* <div>In fase di import delle matricole sono cambiati il CDL e/o la matricola</div> */}
                                    <div style={{ textAlign: 'center' }}><Button onClick={this.openModalMancante} className='mt-2' variant="primary">Gestisci mancante</Button></div>
                                </Alert>
                            </Col>
                        </Row> : null}

                    <Card className='mt-2 mb-2' border={'dark'}>
                        <Card.Header className='blueUnimiBG'>
                            <Row>
                                <Col xl={8}><h4 style={{ color: 'white' }}><FontAwesomeIcon icon={faIdCard} /> ANAGRAFICA STUDENTE</h4></Col>
                                {!this.props.isReadOnly ? <Col xl={4} style={{ textAlign: 'right', color: 'white' }}><FontAwesomeIcon onClick={() => this.openModalMod('anagrafica', this.state.studente.anagrafica, 'M')} className={'modifyCustomButton'} title='Modifica' icon={faPencilAlt} /></Col> : null}
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col xl={6}><span className='customLabel'>Stato studente:</span> {this.state.studente.anagrafica.stato_studente}</Col>
                                    <Col xl={6}><span className='customLabel'>Matricola:</span> {this.state.studente.anagrafica.matricola}</Col>
                                    <Col xl={6}>
                                        <span className='customLabel'>Invio E-mail PT:</span> {this.state.studente.anagrafica.convocazione === 'Y' ? <FontAwesomeIcon color={'green'} icon={faCheck} size={'lg'} /> : this.state.studente.anagrafica.convocazione && this.state.studente.anagrafica.convocazione.toUpperCase() === 'SCELTA NO PERCORSO SLAM' ? "SCELTA NO PERCORSO SLAM" : <FontAwesomeIcon color={'red'} icon={faTimes} size={'lg'} />}
                                    </Col>
                                    <Col xl={6}><span className='customLabel'>Ultima mail PT inviata:</span> {this.state.studente.anagrafica.ultima_mail_pt}</Col>
                                    <Col xl={6}><span className='customLabel'>Cognome:</span> {this.state.studente.anagrafica.cognome}</Col>
                                    <Col xl={6}><span className='customLabel'>Nome:</span> {this.state.studente.anagrafica.nome}</Col>
                                    {/* <Col xl={6}><span className='customLabel'>Codice fiscale:</span> {this.state.studente.anagrafica.cf}</Col> */}
                                    <Col xl={6}><span className='customLabel'>Codice corso di laurea:</span> {this.state.studente.anagrafica.cod_cdl}</Col>
                                    <Col xl={6}><span className='customLabel'>Corso di laurea:</span> <span title={this.state.studente.anagrafica.cdl}>{this.state.studente.anagrafica.cdl.length > 40 ? this.state.studente.anagrafica.cdl.substring(0, 40) + '...' : this.state.studente.anagrafica.cdl}</span></Col>
                                    <Col xl={6}><span className='customLabel'>E-mail:</span> {this.state.studente.anagrafica.email}</Col>
                                    <Col xl={6}><span className='customLabel'>Uscita Cdl:</span> {this.state.studente.anagrafica.uscita_cdl ? this.state.studente.anagrafica.uscita_cdl : '-'}</Col>
                                    <Col xl={6}><span className='customLabel'>Livello:</span> {config.decodeLivello[this.state.studente.anagrafica.livello]}</Col>
                                    <Col xl={6}><span className='customLabel'>Data immatricolazione:</span> {moment(this.state.studente.anagrafica.data_iscrizione).format('DD/MM/YYYY')}</Col>
                                    <Col xl={6}><span className='customLabel'>Cittadinanza:</span> {this.state.studente.anagrafica.cittadinanza}</Col>
                                    <Col xl={6}><span className='customLabel'>Nazione Residenza:</span> {this.state.studente.anagrafica.nazione_residenza} </Col>
                                    
                                    <Col xl={6}><span className='customLabel'>Curriculum:</span> {this.state.studente.anagrafica.cv ? this.state.studente.anagrafica.cv : '-'}</Col>
                                    <Col xl={6}><span className='customLabel'>Tipo Accertamento:</span> {this.state.studente.anagrafica.obb_o_fac ? this.state.studente.anagrafica.obb_o_fac : '-'}</Col>

                                </Row>
                                {/* {!this.props.isReadOnly ? <Row className='d-flex justify-content-center'>
                                    <Button variant='warning'>Modifica <FontAwesomeIcon icon={faPencilAlt} /></Button>
                                </Row> : null} */}
                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                        <Card.Footer className={"text-center"}>
                            {this.state.studente.anagrafica.stato_studente === 'ATTIVO' && this.isStorico() ?
                                <Button className='mr-2' variant="dark" onClick={this.goToStudentHistory} title={'Visualizza storico studente'}>Visualizza storico <FontAwesomeIcon icon={faHistory} /></Button> : this.state.studente.anagrafica.stato_studente !== 'ATTIVO' ? <div className='text-left'>* per le matricole che non risultano in stato <b>ATTIVO</b> la funzionalità storico non è prevista</div> : "Non sono presenti carriere pregresse"}
                            {this.state.studente.anagrafica.doppia_iscrizione === 'Y' ?
                                <div><Button className='mr-2 mt-2' variant="outline-dark" onClick={() => this.reloadPage(this.state.idDoppiaImmatricolazione, this.state.cdlDoppiaImmatricolazione)} title={'Visualizza doppia iscrizione studente'}>Visualizza doppia iscrizione <FontAwesomeIcon icon={faLink} /></Button></div> : null}

                        </Card.Footer>
                    </Card>
                    <CardDeck>
                        <Card className='mt-3 mb-2' style={{ width: !this.state.isMobile ? "50%" : '' }} border={'dark'}>
                            <Card.Header className='blueUnimiBG'>
                                <Row>
                                    <Col xl={8}><h4 style={{ color: 'white' }}> <FontAwesomeIcon icon={faClipboardList} /> PLACEMENT TEST</h4></Col>
                                    {!this.props.isReadOnly ? <Col xl={4} style={{ textAlign: 'right', color: 'white' }}><FontAwesomeIcon onClick={() => this.openModalMod('placement_test', this.state.studente.placement_test, this.state.studente.placement_test ? 'M' : 'I')} className={'modifyCustomButton'} title={this.state.studente.placement_test ? 'Modifica' : 'Aggiungi'} icon={this.state.studente.placement_test ? faPencilAlt : faPlusSquare} />  {this.state.studente.placement_test ? <FontAwesomeIcon onClick={() => this.eliminaEsito(this.state.studente.placement_test.id_valutazione, this.state.studente.placement_test.livello)} className={'modifyCustomButton ml-1'} title='Cancella esito' icon={faTrash} /> : null}</Col> : null}
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {this.state.studente.placement_test ?
                                        <Row>
                                            <Col xl={6}><span className='customLabel'>Data:</span> {moment(this.state.studente.placement_test.data).format('DD/MM/YYYY')} </Col>
                                            <Col xl={6}><span className='customLabel'>Esito:</span> {config.decodeLivello[this.state.studente.placement_test.livello]}</Col>
                                        </Row> :
                                        <Fragment>
                                            <Row className='d-flex justify-content-center'>
                                                <h6>Lo studente non ha sostenuto il Placement Test</h6>
                                            </Row>
                                        </Fragment>}
                                </Card.Text>
                                {/* <Button variant="primary">Go somewhere</Button> */}
                            </Card.Body>
                        </Card>

                        <Card className='mt-3 mb-2' style={{ width: !this.state.isMobile ? "50%" : '' }} border={'dark'}>
                            <Card.Header className='blueUnimiBG'>
                                <Row>
                                    <Col xl={8}><h4 style={{ color: 'white' }}><FontAwesomeIcon icon={faAward} /> CERTIFICAZIONE</h4></Col>
                                    {!this.props.isReadOnly ? <Col xl={4} style={{ textAlign: 'right', color: 'white' }}><FontAwesomeIcon onClick={() => this.openModalMod('certificazione', this.state.studente.certificazione, this.state.studente.certificazione ? 'M' : 'I')} className={'modifyCustomButton'} title={this.state.studente.certificazione ? 'Modifica' : 'Aggiungi'} icon={this.state.studente.certificazione ? faPencilAlt : faPlusSquare} />  {this.state.studente.certificazione ? <FontAwesomeIcon onClick={() => this.eliminaEsito(this.state.studente.certificazione.id_valutazione, this.state.studente.certificazione.livello)} className={'modifyCustomButton ml-1'} title='Cancella esito' icon={faTrash} /> : null} </Col> : null}
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {this.state.studente.certificazione ?
                                        <Row>
                                            <Col xl={6}><span className='customLabel'>Data:</span> {moment(this.state.studente.certificazione.data).format('DD/MM/YYYY')} </Col>
                                            <Col xl={6}><span className='customLabel'>Esito:</span> {config.decodeLivello[this.state.studente.certificazione.livello]}</Col>
                                            <Col xl={6}><span className='customLabel'>Descrizione Certificazione:</span> {this.state.studente.certificazione.descrizione}</Col>
                                        </Row> :
                                        <Fragment>
                                            <Row className='d-flex justify-content-center'>
                                                <h6>Lo studente non possiede una certificazione</h6>
                                            </Row>
                                        </Fragment>}
                                </Card.Text>
                                {/* <Button variant="primary">Go somewhere</Button> */}
                            </Card.Body>
                        </Card>
                    </CardDeck>

                    <CardDeck>

                        <Card className='mt-3 mb-2' style={{ width: !this.state.isMobile ? "50%" : '' }} border={'dark'}>
                            <Card.Header className='blueUnimiBG'>
                                <Row>
                                    <Col xl={8}><h4 style={{ color: 'white' }}><FontAwesomeIcon icon={faChartLine} /> TEST DI INGRESSO</h4></Col>
                                    {!this.props.isReadOnly ? <Col xl={4} style={{ textAlign: 'right', color: 'white' }}><FontAwesomeIcon onClick={() => this.openModalMod('test_di_ingresso', this.state.studente.test_di_ingresso, this.state.studente.test_di_ingresso ? 'M' : 'I')} className={'modifyCustomButton'} title={this.state.studente.test_di_ingresso ? 'Modifica' : 'Aggiungi'} icon={this.state.studente.test_di_ingresso ? faPencilAlt : faPlusSquare} /> {this.state.studente.test_di_ingresso ? <FontAwesomeIcon onClick={() => this.eliminaEsito(this.state.studente.test_di_ingresso.id_valutazione, this.state.studente.test_di_ingresso.livello)} className={'modifyCustomButton ml-1'} title='Cancella esito' icon={faTrash} /> : null} </Col> : null}
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {this.state.studente.test_di_ingresso ?
                                        <Row>
                                            <Col xl={6}><span className='customLabel'>Data:</span> {moment(this.state.studente.test_di_ingresso.data).format('DD/MM/YYYY')} </Col>
                                            <Col xl={6}><span className='customLabel'>Esito:</span> {config.decodeLivello[this.state.studente.test_di_ingresso.livello]}</Col>
                                        </Row> :
                                        <Fragment>
                                            <Row className='d-flex justify-content-center'>
                                                <h6>Lo studente non ha sostenuto un test di ingresso</h6>
                                            </Row>
                                        </Fragment>}
                                </Card.Text>
                                {/* <Button variant="primary">Go somewhere</Button> */}
                            </Card.Body>
                        </Card>

                        <Card className='mt-3 mb-2' style={{ width: !this.state.isMobile ? "50%" : '' }} border={'dark'}>
                            <Card.Header className='blueUnimiBG'>
                                <Row>
                                    <Col xl={8}><h4 style={{ color: 'white' }}><FontAwesomeIcon icon={faUserGraduate} /> LIVELLO TRIENNALE</h4></Col>
                                    {!this.props.isReadOnly ? <Col xl={4} style={{ textAlign: 'right', color: 'white' }}><FontAwesomeIcon onClick={() => this.openModalMod('livello_triennale', this.state.studente.livello_triennale, this.state.studente.livello_triennale ? 'M' : 'I')} className={'modifyCustomButton'} title={this.state.studente.livello_triennale ? 'Modifica' : 'Aggiungi'} icon={this.state.studente.livello_triennale ? faPencilAlt : faPlusSquare} />  {this.state.studente.livello_triennale ? <FontAwesomeIcon onClick={() => this.eliminaEsito(this.state.studente.livello_triennale.id_valutazione, this.state.studente.livello_triennale.livello)} className={'modifyCustomButton ml-1'} title='Cancella esito' icon={faTrash} /> : null} </Col> : null}
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {this.state.studente.livello_triennale ?
                                        <Row>
                                            <Col xl={6}><span className='customLabel'>Data:</span> {moment(this.state.studente.livello_triennale.data).format('DD/MM/YYYY')} </Col>
                                            <Col xl={6}><span className='customLabel'>Esito:</span> {config.decodeLivello[this.state.studente.livello_triennale.livello]}</Col>
                                        </Row> :
                                        <Fragment>
                                            <Row className='d-flex justify-content-center'>
                                                <h6>Lo studente non possiede un livello triennale</h6>
                                            </Row>
                                        </Fragment>}
                                </Card.Text>
                                {/* <Button variant="primary">Go somewhere</Button> */}
                            </Card.Body>
                        </Card>
                    </CardDeck>
                    <Card className='mt-3 mb-2' border={'dark'}>
                        <Card.Header className='blueUnimiBG'>
                            <Row>
                                <Col xl={8}> <h4 style={{ color: 'white' }}><FontAwesomeIcon icon={faCheckDouble} /> TEST FINALE</h4></Col>
                                {!this.props.isReadOnly ? <Col xl={4} style={{ textAlign: 'right', color: 'white' }}>{!this.state.studente.test_finale ? <FontAwesomeIcon onClick={() => this.openModalMod('test_finale', this.state.studente.test_finale, 'I')} className={'modifyCustomButton'} title={'Aggiungi'} icon={faPlusSquare} /> : null} {this.state.studente.test_finale ? <FontAwesomeIcon onClick={() => this.eliminaEsito(this.state.studente.test_finale[0].id_valutazione, this.state.studente.test_finale[0].livello)} className={'modifyCustomButton ml-1'} title='Cancella esito' icon={faTrash} /> : null} </Col> : null}
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {this.state.studente.test_finale ?
                                    <Row>
                                        <Col xl={12}>
                                            <DataTable
                                                className={'tableCustomHeader'}
                                                columns={this.columns}
                                                data={this.state.studente.test_finale}
                                                expandableRows = {this.state.studente.test_finale[0].tentativi_successivi.length > 0 ? true : false}
                                                expandableRowsComponent={ExpandedComponent}
                                                expandableRowDisabled={row => row.disabled}
                                                striped
                                                highlightOnHover
                                                responsive
                                                noHeader={true}
                                            />
                                        </Col>
                                    </Row> :
                                    <Fragment>
                                        <Row className='d-flex justify-content-center'>
                                            <h6>Lo studente non ha sostenuto ancora alcun test finale</h6>
                                        </Row>
                                    </Fragment>}
                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                    </Card>


                </Fragment> : <Loader />}
            </div>)
    }
}

export default withRouter(DettaglioStudente);